<template>
  <div
    v-html="data"
  />
</template>

<script>
import { findAppUseAgreement } from '@/api/agreement'
export default {
  name: 'AppUse',
  data() {
    return {
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAppUseAgreement().then((res) => {
        if (res.code === 0) {
          console.log(res.data)
          this.data = res.data.content
        }
      })
    }
  }
}
</script>

